<template>
  <screen-grid :full-height="true">
    <screen-card class="locations-gmb-list-google-updates" :full-height="true">
      <template v-slot:body>
        <ui-data-search
          :element-fixed="elementFixed"
          :scroll-direction="scrollDirection"
          :is-mobile="isMobile"
          :is-tablet="isTablet"
          :is-loading="isLoading"
          :is-scrolling="isScrolling"
          view="locationsGmb"
          ref="fixedElement"
          @search="onSearch"
          :with-search="true"
          :with-filter="false"
        />
        <div class="locations-gmb-list-google-updates__list">
          <ui-data-list
            :items="googleUpdates"
            :is-loading="isLoading || isUpdating"
            :is-scrolling="isScrolling"
            :skeleton-loop="googleUpdates.length"
            :pagination="googleUpdatesPagination"
            :is-paginate="true"
            @click="goToLocation({ row: { name: $event.name } })"
            @nextPage="loadGmbGoogleUpdates(null, true, false, true, 1)"
            @prevPage="loadGmbGoogleUpdates(null, true, false, true, -1)"
          >
            <template v-slot:skeleton>
              <div class="locations-gmb-list-google-updates__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <div class="locations-gmb-list-google-updates__list__content">
                <div class="locations-gmb-list-google-updates__list__content__first-line">
                  <div class="locations-gmb-list-google-updates__list__content__name">
                    {{ slotProps.item.location_name }}
                  </div>
                  <div>
                    {{ formatedDate(slotProps.item.date, 'L LT', $i18n.locale) }}
                  </div>
                </div>
                <v-chip-group column>
                  <v-chip
                    class="locations-gmb-list-google-updates__list__content__chip"
                    v-for="tag in slotProps.item.fields.split(',')"
                    :key="tag"
                    small
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </div>
            </template>
          </ui-data-list>
        </div>
        <div class="locations-gmb-list-google-updates__table">
          <ui-data-table
            :columns="columns"
            :rows="googleUpdates"
            :total-rows="totalRows"
            :is-loading="isLoading || isUpdating"
            :with-pagination="true"
            :pagination="googleUpdatesPagination"
            @loadItems="
              loadGmbGoogleUpdates({
                tableParams: { ...$event },
                isUpdating: true,
                resetLocations: false,
              })
            "
            @onCellClick="goToLocation"
          >
            <template slot="data-table-row" slot-scope="{ column, row, formattedRow }">
              <router-link
                class="locations-gmb-list-google-updates__table__link"
                :to="{
                  name: 'LocationGmb',
                  params: { name: buildAccountLocationName(row), currentTab: 'googleUpdates' },
                }"
              />
              <span v-if="column.field === 'location_name'">
                <div class="locations-gmb-list-google-updates__table__block">
                  <div class="locations-gmb-list-google-updates__table__block__content">
                    <div class="locations-gmb-list-google-updates__table__block__content__name">
                      {{ row['location_name'] }}
                    </div>
                  </div>
                </div>
              </span>
              <span v-else-if="column.field === 'date'">
                {{ formatedDate(row['date'], 'L LT', $i18n.locale) }}
              </span>
              <v-chip-group v-else-if="column.field === 'fields'" column>
                <v-chip
                  small
                  class="locations-gmb-list-google-updates__table__block__content__chip"
                  v-for="(tag, index) in row['fields'].split(',')"
                  :key="`fields tag ${index}`"
                >
                  {{ tag }}
                </v-chip>
              </v-chip-group>
              <span
                :class="{
                  'locations-gmb-list-google-updates__table__block__content--medium': formattedRow[column.field] > 5,
                  'locations-gmb-list-google-updates__table__block__content--alert': formattedRow[column.field] > 10,
                }"
                v-else-if="column.field === 'count_gmb_upd'"
              >
                {{ formattedRow[column.field] }}
              </span>
              <template v-else>
                {{ formattedRow[column.field] }}
              </template>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { onScroll } from '@/mixins/scroll.mixin'
import { formatedDate } from '@/utils/date.util'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'

export default {
  name: 'googleUpdatesListGoogleUpdates',
  mixins: [onScroll],
  components: {
    UiDataTable,
    UiDataList,
    UiDataSearch,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    googleUpdates: {
      type: Array,
      required: true,
    },
    googleUpdatesPagination: {
      type: Object,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formatedDate,
      stringifiedFilters: '',
    }
  },
  watch: {
    stringifiedFilters() {
      this.$emit('loadGoogleUpdates', {
        tableParams: { filters: this.stringifiedFilters },
        resetLocations: false,
        isUpdating: true,
      })
    },
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('locationsGmb.list.column.locationName'),
          field: 'location_name',
        },
        {
          label: this.$t('locationsGmb.list.column.id'),
          field: 'id',
        },
        {
          label: this.$t('locationsGmb.list.column.fieldsUpdate'),
          field: 'fields',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.lastUpdate'),
          field: 'date',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.updateCount'),
          field: 'count_gmb_upd',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    buildAccountLocationName(location) {
      return `${location.account}/${location.name}`
    },
    goToLocation(e) {
      this.$router.push({
        name: 'LocationGmb',
        params: { name: this.buildAccountLocationName(e.row), currentTab: 'googleUpdates' },
      })
    },
    onSearch(value) {
      this.$emit('onSearch', value)
    },
    loadGmbGoogleUpdates(params) {
      this.$emit('loadGmbGoogleUpdates', params)
    },
  },
}
</script>

<style lang="scss" scoped>
.locations-gmb-list-google-updates {
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__select {
      margin-left: 7px;
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;

      &__first-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &__chip {
        background-color: $blue-dodger !important;
        color: $white !important;
      }

      &__name {
        @include text-ellipsis(1, 0.875rem);

        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &--button-active {
      height: calc(100% - #{$input-min-height});
    }

    &__link {
      @include full-link;
    }
    &__select {
      width: auto;
      height: auto;
      color: $grey-french;
    }

    &__button {
      display: flex;
      justify-content: center;
    }

    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;

        &--medium {
          color: $orange-tree-poppy;
          font-weight: 700;
        }

        &--alert {
          color: $red-pomegranate;
          font-weight: 700;
        }

        &__chip {
          background-color: $blue-dodger !important;
          color: $white !important;
        }

        &__name {
          @include text-ellipsis(1, 0.875rem);

          font-weight: 600;
        }
        &__address {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-sm;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'influence');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }
  }
}
</style>
