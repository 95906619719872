<template>
  <div class="locations-gmb-list">
    <screen-header
      class="locations-gmb-list__header"
      :title="
        $tc('locationsGmb.list.title', totalLocationsRows, {
          brand: currentClient.name,
          count: totalLocationsRows,
        })
      "
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading && !isScrolling"
      :right-content="true"
    >
      <skeleton-icon v-if="isLoading" margin-left="16px" width="44px" height="44px" />
      <v-popover
        placement="bottom-end"
        :autoHide="true"
        container=".locations-gmb-list__header"
        popoverInnerClass="popover-inner no-overflow"
        v-else
      >
        <ui-button
          class="locations-gmb-list__header__cta"
          button="primary"
          variant="data"
          icon="store"
          :label="currentAccount.accountName"
          :mobile-toggle="true"
          v-if="accounts.length > 1 && currentAccount"
        />
        <template slot="popover">
          <div class="locations-gmb-list__header__popover">
            <ui-dropdown
              class="locations-gmb-list__header__popover__dropdown"
              id="dropdown-gmb-acounts"
              v-model="modelAccount"
              :options="accounts"
              :placeholder="$t('locations.id.header.frontOffice.placeholder')"
              :dropdown-label="$t('locations.id.header.frontOffice.label')"
              label="accountName"
              track-by="name"
              :show-label="true"
            />
          </div>
        </template>
      </v-popover>
    </screen-header>
    <screen-container class="tw-pb-0" direction="column">
      <screen-tabs
        :tabs="tabs"
        :element-fixed="false"
        :current-tab="currentTab || 'locations'"
        :is-loading="isLoading"
        @click="setCurrentTab"
      />
    </screen-container>
    <screen-container :full-height="true">
      <gmb-list-locations
        v-if="currentTab === 'locations'"
        :is-updating="isUpdating"
        :is-loading="isLoading"
        :is-scrolling="isScrolling"
        :locations-gmb="locationsGmb"
        :locations-gmb-pagination="locationsGmbPagination"
        :total-rows="totalLocationsRows"
        @onSearch="onSearch"
        @loadGmbLocations="params => loadGmbLocations(params)"
      />
      <gmb-list-google-updates
        v-if="currentTab === 'googleUpdates'"
        :is-updating="isUpdatesUpdating"
        :is-loading="isUpdatesLoading"
        :is-scrolling="isUpdatesScrolling"
        :google-updates="googleUpdatesLocations"
        :google-updates-pagination="googleUpdatesLocationsPagination"
        :total-rows="totalUpdatesRows"
        @onSearch="onSearch"
        @loadGmbGoogleUpdates="params => loadGmbGoogleUpdates(params)"
      />
    </screen-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GmbListLocations from '@/components/LocationGmb/List/Locations.vue'
import GmbListGoogleUpdates from '@/components/LocationGmb/List/GoogleUpdates.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'

export default {
  name: 'GmbLocationsList',
  components: {
    GmbListLocations,
    GmbListGoogleUpdates,
    UiButton,
    UiDropdown,
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    SkeletonIcon,
  },
  data() {
    return {
      search: '',
      isLoading: false,
      isUpdating: false,
      isScrolling: false,
      isUpdatesLoading: false,
      isUpdatesUpdating: false,
      isUpdatesScrolling: false,
    }
  },
  async created() {
    this.isLoading = true
    await this.getAccounts({})
    if (!this.$route.params.name && this.accounts?.length > 0) {
      this.$router.replace({
        ...this.$route,
        params: {
          ...this.$route.params,
          name: this.accounts[0].name,
        },
      })
    }
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('locations')
    }
    this.isLoading = false
  },
  watch: {
    '$route.params.name': {
      immediate: true,
      async handler() {
        if (this.$route.params.name) {
          await Promise.all([this.loadGmbLocations({}), this.loadGmbGoogleUpdates({})])
        }
      },
    },
    search() {
      if (this.$route.params.currentTab === 'googleUpdates') {
        this.loadGmbGoogleUpdates({ isUpdating: true })
      } else {
        this.loadGmbLocations({ isUpdating: true })
      }
    },
  },
  computed: {
    ...mapState({
      locationsGmbPagination: state => state.gmb.locationsGmbPagination,
      locationsGmb: state => state.gmb.locationsGmb.data,
      googleUpdatesLocations: state => state.gmb.googleUpdatesLocations,
      googleUpdatesLocationsPagination: state => state.gmb.googleUpdatesLocationsPagination,
      currentClient: state => state.client.currentClient,
      accounts: state => state.gmb.accounts,
    }),
    tabs() {
      return [
        {
          label: this.$t('locationsGmb.list.tab.locations'),
          key: 'locations',
        },
        {
          label: this.$t('locationsGmb.list.tab.googleUpdates'),
          key: 'googleUpdates',
        },
      ]
    },
    totalLocationsRows() {
      return parseInt(this.locationsGmbPagination.nbResults) || 0
    },
    totalUpdatesRows() {
      return parseInt(this.googleUpdatesLocationsPagination.nbResults) || 0
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('locationsGmb.list.breadcrumb'),
          route: {
            name: 'LocationsGmb',
            params: {
              name: this.currentAccountName,
            },
          },
        },
      ]
    },
    currentAccount() {
      return this.accounts.find(account => account.name === this.currentAccountName)
    },
    currentAccountName() {
      return this.$route.params.name
    },
    currentTab() {
      return this.$route.params.currentTab
    },
    modelAccount: {
      get() {
        return this.currentAccountName
      },
      async set({ name }) {
        this.$router.push({ ...this.$route, params: { ...this.$route.params, name } })
      },
    },
  },
  methods: {
    ...mapActions({
      getAccounts: 'gmb/getAccounts',
      loadLocations: 'gmb/loadLocations',
      loadGoogleUpdates: 'gmb/loadGoogleUpdates',
      loadMoreLocations: 'gmb/loadMoreLocations',
    }),
    onSearch(value) {
      this.search = value
    },
    async loadGmbGoogleUpdates({
      tableParams = null,
      isUpdating = false,
      resetLocations = true,
      mobile = false,
      pageEvent,
    }) {
      if (isUpdating) {
        this.isUpdatesUpdating = true
      } else {
        this.isUpdatesLoading = true
      }
      const mobileParams = this.scrollMobile(mobile, pageEvent)
      try {
        await this.loadGoogleUpdates({
          accountName: this.currentAccountName,
          tableParams: mobile ? mobileParams : tableParams,
          resetLocations,
          search: this.search,
        })
        this.resetUpdatesEvent()
      } catch {
        this.resetUpdatesEvent()
      }
    },
    async loadGmbLocations({
      tableParams = null,
      isUpdating = false,
      resetLocations = true,
      mobile = false,
      pageEvent,
    }) {
      if (isUpdating) {
        this.isUpdating = true
      } else {
        this.isLoading = true
      }
      const mobileParams = this.scrollMobile(mobile, pageEvent)
      try {
        await this.loadLocations({
          accountName: this.currentAccountName,
          tableParams: mobile ? mobileParams : tableParams,
          resetLocations,
          search: this.search,
        })
        this.resetEvent()
      } catch {
        this.resetEvent()
      }
    },
    scrollMobile(mobile, pageEvent) {
      if (mobile) {
        document.body.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
        return {
          page: parseInt(this.locationsGmbPagination.page) + pageEvent,
        }
      }
    },
    resetEvent() {
      this.isScrolling = false
      this.isLoading = false
      this.isUpdating = false
    },
    resetUpdatesEvent() {
      this.isUpdatesScrolling = false
      this.isUpdatesLoading = false
      this.isUpdatesUpdating = false
    },
    addLocation() {
      this.$router.push({ name: 'LocationCreate' })
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route.name, params: { ...this.$route.params, currentTab: tab } })
    },
  },
}
</script>

<style lang="scss" scoped>
.locations-gmb-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }
  &__header {
    &__cta {
      margin-left: $gutter-mobile;

      @media (min-width: $screen-sm) {
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
    &__popover {
      padding: $gutter-mobile;
      min-width: 280px;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet;
        min-width: 340px;
      }
      &__dropdown {
        margin-top: $gutter-mobile;

        @media (min-width: $screen-sm) {
          margin-top: $gutter-tablet;
        }
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
