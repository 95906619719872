<template>
  <div>
    <div class="gmb-list-filters">
      <div class="gmb-list-filters__section">
        <div class="gmb-list-filters__section__label">
          {{ $t('locationsGmb.list.filters.isDuplicate') }}
        </div>
        <div class="gmb-list-filters__section__radios">
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="isDuplicate-all"
            value="ENABLED|DISABLED"
            name="isDuplicate"
            :reverse="true"
            v-model="filters.isDuplicate"
          >
            {{ $t('locationsGmb.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="isDuplicate-enabled"
            value="ENABLED"
            name="isDuplicate"
            :reverse="true"
            v-model="filters.isDuplicate"
          >
            {{ $t('locationsGmb.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="isDuplicate-disabled"
            value="DISABLED"
            name="isDuplicate"
            :reverse="true"
            v-model="filters.isDuplicate"
          >
            {{ $t('locationsGmb.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="gmb-list-filters__section">
        <div class="gmb-list-filters__section__label">
          {{ $t('locationsGmb.list.filters.hasGoogleUpdated') }}
        </div>
        <div class="gmb-list-filters__section__radios">
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="hasGoogleUpdated-all"
            value="ENABLED|DISABLED"
            name="hasGoogleUpdated"
            :reverse="true"
            v-model="filters.hasGoogleUpdated"
          >
            {{ $t('locationsGmb.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="hasGoogleUpdated-enabled"
            value="ENABLED"
            name="hasGoogleUpdated"
            :reverse="true"
            v-model="filters.hasGoogleUpdated"
          >
            {{ $t('locationsGmb.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="hasGoogleUpdated-disabled"
            value="DISABLED"
            name="hasGoogleUpdated"
            :reverse="true"
            v-model="filters.hasGoogleUpdated"
          >
            {{ $t('locationsGmb.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="gmb-list-filters__section">
        <div class="gmb-list-filters__section__label">
          {{ $t('locationsGmb.list.filters.hasPendingEdits') }}
        </div>
        <div class="gmb-list-filters__section__radios">
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="hasPendingEdits-all"
            value="ENABLED|DISABLED"
            name="hasPendingEdits"
            :reverse="true"
            v-model="filters.hasPendingEdits"
          >
            {{ $t('locationsGmb.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="hasPendingEdits-enabled"
            value="ENABLED"
            name="hasPendingEdits"
            :reverse="true"
            v-model="filters.hasPendingEdits"
          >
            {{ $t('locationsGmb.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="hasPendingEdits-disabled"
            value="DISABLED"
            name="hasPendingEdits"
            :reverse="true"
            v-model="filters.hasPendingEdits"
          >
            {{ $t('locationsGmb.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="gmb-list-filters__section">
        <div class="gmb-list-filters__section__label">
          {{ $t('locationsGmb.list.filters.lpeStatus') }}
        </div>
        <div class="gmb-list-filters__section__radios">
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="lpeStatus-all"
            value="ENABLED|DISABLED"
            name="lpeStatus"
            :reverse="true"
            v-model="filters.lpeStatus"
          >
            {{ $t('locationsGmb.list.filters.all') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="lpeStatus-enabled"
            value="ENABLED"
            name="lpeStatus"
            :reverse="true"
            v-model="filters.lpeStatus"
          >
            {{ $t('locationsGmb.list.filters.enabled') }}
          </ui-radio>
          <ui-radio
            class="gmb-list-filters__section__radios__radio"
            id="lpeStatus-disabled"
            value="DISABLED"
            name="lpeStatus"
            :reverse="true"
            v-model="filters.lpeStatus"
          >
            {{ $t('locationsGmb.list.filters.disabled') }}
          </ui-radio>
        </div>
      </div>
      <div class="gmb-list-filters__section__buttons">
        <div class="gmb-list-filters__section__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locationsGmb.list.filters.reset')" @click="reset" />
        </div>
        <div class="gmb-list-filters__section__buttons__close">
          <ui-button button="primary" v-close-popover icon="close" :label="$t('locationsGmb.list.filters.close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiButton from '@/components/UI/Button.vue'

const getSimpleFieldParams = (key, value) => {
  switch (value) {
    case 'ENABLED':
      return `${key}:1`
    case 'DISABLED':
      return `${key}!1`
    case 'ENABLED|DISABLED':
    default:
      return ''
  }
}

const defaultFilters = {
  isDuplicate: 'ENABLED|DISABLED',
  hasGoogleUpdated: 'ENABLED|DISABLED',
  hasPendingEdits: 'ENABLED|DISABLED',
  lpeStatus: 'ENABLED|DISABLED',
}

export default {
  name: 'LocationListFilter',
  components: {
    UiRadio,
    UiButton,
  },
  data() {
    return {
      filters: {
        ...defaultFilters,
      },
    }
  },
  computed: {
    stringifiedSearch() {
      return [
        getSimpleFieldParams('isDuplicate', this.filters.isDuplicate),
        getSimpleFieldParams('hasGoogleUpdated', this.filters.hasGoogleUpdated),
        getSimpleFieldParams('hasPendingEdits', this.filters.hasPendingEdits),
        getSimpleFieldParams('lpeStatus', this.filters.lpeStatus),
      ]
        .filter(filter => filter && filter.length > 0)
        .join(',')
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'onUpdateFilters',
    },
  },
  methods: {
    onUpdateFilters() {
      this.$emit('onUpdateFilters', this.stringifiedSearch)
    },
    reset() {
      this.filters = { ...defaultFilters }
    },
  },
}
</script>

<style lang="scss" scoped>
.gmb-list-filters {
  margin: 0 auto;

  &__section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &__reset {
        cursor: pointer;
      }
    }

    &__label {
      margin-bottom: 0.5rem;
    }

    &__label,
    &__checkbox {
      font-weight: 600;
    }

    &__radios {
      display: flex;
      justify-content: space-between;
      &__radio {
        margin: 0 0.5rem;

        &:first-child {
          margin: 0;
          margin-right: 0.5rem;
        }
        &:last-child {
          margin: 0;
          margin-left: 0.5rem;
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
  }
}
</style>
