<template>
  <screen-grid :full-height="true">
    <screen-card class="locations-gmb-list-locations" :full-height="true">
      <template v-slot:body>
        <ui-data-search
          :element-fixed="elementFixed"
          :scroll-direction="scrollDirection"
          :is-mobile="isMobile"
          :is-tablet="isTablet"
          :is-loading="isLoading"
          :is-scrolling="isScrolling"
          view="locationsGmb"
          ref="fixedElement"
          @search="onSearch"
          :with-search="true"
          :with-filter="true"
        >
          <template v-slot:filterSlot>
            <gmb-list-filters @onUpdateFilters="onUpdateFilters($event)" />
          </template>
        </ui-data-search>
        <div class="locations-gmb-list-locations__list">
          <ui-data-list
            :items="locationsGmb"
            :is-loading="isLoading || isUpdating"
            :is-scrolling="isScrolling"
            :skeleton-loop="locationsGmb.length"
            :pagination="locationsGmbPagination"
            :is-paginate="true"
            @click="goToLocation({ row: { name: $event.name } })"
            @nextPage="
              loadGmbLocations({
                tableParams: null,
                isUpdating: true,
                resetLocations: false,
                mobile: true,
                pageEvent: 1,
              })
            "
            @prevPage="
              loadGmbLocations({
                tableParams: null,
                isUpdating: true,
                resetLocations: false,
                mobile: true,
                pageEvent: -1,
              })
            "
          >
            <template v-slot:skeleton>
              <div class="locations-gmb-list-locations__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <div class="locations-gmb-list-locations__list__content">
                <div class="locations-gmb-list-locations__list__content__name">
                  {{ slotProps.item.location_name }}
                </div>
                <address class="locations-gmb-list-locations__list__content__address">
                  {{ addressLines(slotProps.item) }}
                </address>
              </div>
              <span
                class="locations-gmb-list-locations__list__status backoffice-icons"
                :class="[`locations-gmb-list-locations__list__status--${slotProps.item.verified_status}`]"
              >
                <template v-if="slotProps.item.verified_status === '1'"> success </template>
                <template v-else>error</template>
              </span>
            </template>
          </ui-data-list>
        </div>
        <div class="locations-gmb-list-locations__table">
          <ui-data-table
            :columns="columns"
            :rows="locationsGmb"
            :total-rows="totalRows"
            :is-loading="isLoading || isUpdating"
            :with-pagination="true"
            :pagination="locationsGmbPagination"
            @loadItems="
              loadGmbLocations({
                tableParams: { ...$event },
                isUpdating: true,
                resetLocations: false,
              })
            "
            @onCellClick="goToLocation"
          >
            <template slot="data-table-row" slot-scope="props">
              <router-link
                class="locations-gmb-list-locations__table__link"
                :to="{ name: 'LocationGmb', params: { name: buildAccountLocationName(props.row) } }"
              />
              <span v-if="props.column.field === 'location_name'">
                <div class="locations-gmb-list-locations__table__block">
                  <div class="locations-gmb-list-locations__table__block__content">
                    <div class="locations-gmb-list-locations__table__block__content__name">
                      {{ props.row['location_name'] }}
                    </div>
                    <address class="locations-gmb-list-locations__table__block__content__address">
                      {{ addressLines(props.row) }}
                    </address>
                  </div>
                </div>
              </span>
              <span v-if="props.column.field === 'store_code'">
                {{ props.row['store_code'] }}
              </span>
              <span v-else-if="props.column.field === 'place_id'">
                {{ props.row['place_id'] }}
              </span>
              <span v-else-if="props.column.field === 'verified_status'">
                <span
                  class="locations-gmb-list-locations__table__status backoffice-icons"
                  :class="[`locations-gmb-list-locations__table__status--${props.row['verified_status']}`]"
                >
                  <template v-if="props.row['verified_status'] === '1'"> check_marked_outline </template>
                  <template v-else>close_circle_outline</template>
                </span>
              </span>
              <span v-else-if="props.column.field === 'open_status'">
                {{ props.row['open_status'] }}
              </span>
              <span v-else-if="props.column.field === 'isDuplicate'">
                <span
                  class="locations-gmb-list-locations__table__status backoffice-icons"
                  :class="[`locations-gmb-list-locations__table__status--${+!props.row['isDuplicate']}`]"
                >
                  <template v-if="props.row['isDuplicate'] === 1"> close_circle_outline </template>
                  <template v-else>check_marked_outline</template>
                </span>
              </span>
              <span v-else-if="props.column.field === 'hasGoogleUpdated'">
                <span
                  class="locations-gmb-list-locations__table__status-updates backoffice-icons"
                  :class="[`locations-gmb-list-locations__table__status-updates--${props.row['hasGoogleUpdated']}`]"
                >
                  <template v-if="props.row['hasGoogleUpdated'] === 1"> progress_alert </template>
                  <template v-else>check_marked_outline</template>
                </span>
              </span>
              <span v-else-if="props.column.field === 'hasPendingEdits'">
                <span
                  class="locations-gmb-list-locations__table__status-synchro backoffice-icons"
                  :class="[`locations-gmb-list-locations__table__status-synchro--${props.row['hasPendingEdits']}`]"
                >
                  <template v-if="props.row['hasPendingEdits'] === 1"> cloud_sync_outline </template>
                  <template v-else>cloud_check_outline</template>
                </span>
              </span>
              <span v-else-if="props.column.field === 'select'">
                <router-link :to="{ name: 'LocationGmb', params: { name: props.row['name'] } }">
                  <ui-button
                    class="locations-gmb-list-locations__table__select"
                    button="primary"
                    icon="chevron_right"
                    :label="$t('locations.list.link')"
                    :iconOnly="true"
                  />
                </router-link>
              </span>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import { mdiClockOutline } from '@mdi/js'
import { onScroll } from '@/mixins/scroll.mixin'
import GmbListFilters from '@/views/Gmb/ListFilters'
import UiDataSearch from '@/components/UI/DataSearch.vue'
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'

export default {
  name: 'LocationsGmbListLocations',
  mixins: [onScroll],
  components: {
    GmbListFilters,
    UiDataTable,
    UiDataList,
    UiDataSearch,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    isUpdating: {
      type: Boolean,
      required: true,
    },
    isScrolling: {
      type: Boolean,
      required: true,
    },
    locationsGmb: {
      type: Array,
      required: true,
    },
    locationsGmbPagination: {
      type: Object,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stringifiedFilters: '',
      icons: {
        mdiClockOutline,
      },
    }
  },
  watch: {
    stringifiedFilters() {
      this.$emit('loadGmbLocations', {
        tableParams: { filters: this.stringifiedFilters },
        resetLocations: false,
        isUpdating: true,
      })
    },
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('locationsGmb.list.column.locationName'),
          field: 'location_name',
        },
        {
          label: this.$t('locationsGmb.list.column.storeCode'),
          field: 'store_code',
        },
        {
          label: this.$t('locationsGmb.list.column.placeId'),
          field: 'place_id',
          sortable: false,
        },
        {
          label: this.$t('locationsGmb.list.column.status'),
          field: 'verified_status',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('locationsGmb.list.column.isSingle'),
          field: 'isDuplicate',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('locationsGmb.list.column.hasGoogleUpdated'),
          field: 'hasGoogleUpdated',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('locationsGmb.list.column.hasPendingEdits'),
          field: 'hasPendingEdits',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: this.$t('locationsGmb.list.column.openInfo'),
          field: 'open_status',
        },
      ]
    },
  },
  methods: {
    addressLines(item) {
      let address = ''

      if (item.street1?.length > 0) {
        address += item.street1
      }

      if (item.postalCode) {
        address += `, ${item.postalCode}`
      }

      if (item.city) {
        address += ` ${item.city}`
      }
      return address
    },
    buildAccountLocationName(location) {
      return `${location.account}/${location.name}`
    },
    goToLocation(e) {
      this.$router.push({ name: 'LocationGmb', params: { name: this.buildAccountLocationName(e.row) } })
    },
    onSearch(value) {
      this.$emit('onSearch', value)
    },
    onUpdateFilters(stringifiedFilters) {
      this.stringifiedFilters = stringifiedFilters
    },
    loadGmbLocations(params) {
      this.$emit('loadGmbLocations', params)
    },
  },
}
</script>

<style lang="scss" scoped>
.locations-gmb-list-locations {
  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }
    &__select {
      margin-left: 7px;
      width: auto;
      height: auto;
      color: $grey-french;
    }
    &__logo {
      margin-right: $gutter-mobile;
      width: 36px;
      height: 36px;

      @media (min-width: $screen-sm) {
        margin-right: $gutter-tablet;
      }
    }
    &__content {
      flex: 1;
      &__name {
        @include text-ellipsis(1, 0.875rem);

        font-weight: 600;
      }
      &__address {
        @include text-ellipsis(1, 12px);

        margin-top: 3px;
        font-size: $font-size-sm;
        font-style: normal;

        @media (min-width: $screen-sm) {
          @include text-ellipsis(1, 14px);

          margin-right: $gutter-tablet;
          font-size: $font-size-default;
        }
      }
    }
    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }

    &__button {
      display: flex;
      justify-content: center;
      padding: 1rem;
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &--button-active {
      height: calc(100% - #{$input-min-height});
    }

    &__link {
      @include full-link;
    }
    &__select {
      width: auto;
      height: auto;
      color: $grey-french;
    }

    &__button {
      display: flex;
      justify-content: center;
    }

    &__status {
      color: map-get($generic-color-variants, 'error');
      &--1,
      &--10,
      &--20 {
        color: map-get($generic-color-variants, 'success');
      }
    }

    &__status-updates {
      color: map-get($generic-color-variants, 'success');

      &--1 {
        color: map-get($generic-color-variants, 'warning');
      }
    }

    &__status-synchro {
      color: map-get($generic-color-variants, 'success');

      &--1 {
        color: map-get($generic-color-variants, 'data');
      }
    }

    &__block {
      display: flex;
      align-items: center;
      &__logo {
        width: 36px;
        height: 36px;

        @media (min-width: $screen-md) {
          margin-right: $gutter-tablet;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          @include text-ellipsis(1, 0.875rem);

          font-weight: 600;
        }
        &__address {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
          font-size: $font-size-sm;
          font-style: normal;
        }
      }
      &__icon {
        border: 1px solid;
        border-radius: 50%;
        padding: 3px;
        color: map-get($generic-color-variants, 'influence');
        &:first-child {
          margin-right: 8px;
        }
        &--inactive {
          color: $generic-color-bg-disabled;
        }
      }
    }
  }
}
</style>
